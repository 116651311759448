<template>
  <div class="bill-type-selector">
    <div class="bill-type-selector-side bill-type-selector-left">
      <div class="selector-title">支出</div>
      <div class="selector-items-container">
        <div class="selector-items">
          <div
            class="selector-item"
            :class="{ 'selector-item-select': focusId === tp.id }"
            v-for="(tp, tpIndex) in billTypeSpendingShow"
            :key="tpIndex"
            @click="handleClickType(tp.id)"
            :id="tp.id"
          >
            <div class="selector-item-icon-container">
              <i
                :class="tp.icon"
                class="selector-item-icon"
                :style="'color:' + tp.color"
              ></i>
            </div>
            <div class="selector-item-name">
              {{ tp.name }}
            </div>
            <div class="selector-item-tag-container">
              <el-tag v-if="!tp.createdAt">默 认</el-tag>
              <el-tag v-if="tp.createdAt" type="warning">自定义</el-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bill-type-selector-side bill-type-selector-right">
      <div class="selector-title">收入</div>
      <div class="selector-items-container">
        <div class="selector-items">
          <div
            class="selector-item"
            :class="{ 'selector-item-select': focusId === tp.id }"
            v-for="(tp, tpIndex) in billTypeIncomeShow"
            :key="tpIndex"
            @click="handleClickType(tp.id)"
            :id="tp.id"
          >
            <div class="selector-item-icon-container">
              <i
                :class="tp.icon"
                :style="'color:' + tp.color"
                class="selector-item-icon"
              ></i>
            </div>
            <div class="selector-item-name">
              {{ tp.name }}
            </div>
            <div class="selector-item-tag-container">
              <el-tag v-if="!tp.createdAt">默 认</el-tag>
              <el-tag v-if="tp.createdAt" type="warning">自定义</el-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { match as pinyinMatch } from "pinyin-pro";
export default {
  name: "BillTypeSelector",
  props: {
    typeQuickFilter: {
      type: String,
      default: () => {
        return "";
      },
    },
    billTypeIncome: {
      type: Array,
      default: () => {
        return [];
      },
    },
    billTypeSpending: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    idMatrix() {
      // [
      //   ["sp-1", "sp-2", "sp-3", "sp-4", "a3c24a"],
      //   ["in-1", "in-2", "in-3", "in-4", "b23aa1"]
      // ]
      let res = [];
      const spIdList = this.billTypeSpendingShow.map((item) => item.id);
      const inIdList = this.billTypeIncomeShow.map((item) => item.id);
      res.push(spIdList);
      res.push(inIdList);
      return res;
    },
    billTypeIncomeShow() {
      let res = this.billTypeIncome;
      if (this.typeQuickFilter) {
        res = this.billTypeIncome.filter(
          (item) =>
            item.name.includes(this.typeQuickFilter) ||
            pinyinMatch(item.name, this.typeQuickFilter)
        );
      }
      return res;
    },
    billTypeSpendingShow() {
      let res = this.billTypeSpending;
      if (this.typeQuickFilter) {
        res = this.billTypeSpending.filter(
          (item) =>
            item.name.includes(this.typeQuickFilter) ||
            pinyinMatch(item.name, this.typeQuickFilter)
        );
      }
      return res;
    },
  },
  data() {
    return {
      focusId: "",
    };
  },
  methods: {
    handleClickType(id) {
      this.$emit("set-type", id);
      this.focusId = "";
    },
    handleArrow(direction) {
      console.log("[handleArrow]->", direction);
      if (!this.focusId) {
        this.focusId = this.idMatrix[0][0];
      } else {
        const spOne = this.billTypeSpending.find(
          (item) => item.id === this.focusId
        );
        const inOne = this.billTypeIncome.find(
          (item) => item.id === this.focusId
        );

        if (spOne && !inOne) {
          // spending

          let add = 0;
          let _t = 0;
          let index = this.idMatrix[_t].indexOf(this.focusId);
          if (direction === "ARROWDOWN") {
            add = 1;
          } else if (direction === "ARROWUP") {
            add = -1;
          } else if (direction === "ARROWLEFT") {
            _t = 0;
            index = 0;
          } else if (direction === "ARROWRIGHT") {
            _t = 1;
            index = 0;
          }
          const spNext = this.idMatrix[_t][index + add];
          if (spNext) {
            this.focusId = spNext;
          }
        } else if (!spOne && inOne) {
          // income

          let add = 0;
          let _t = 1;
          let index = this.idMatrix[_t].indexOf(this.focusId);
          if (direction === "ARROWDOWN") {
            add = 1;
          } else if (direction === "ARROWUP") {
            add = -1;
          } else if (direction === "ARROWLEFT") {
            _t = 0;
            index = 0;
          } else if (direction === "ARROWRIGHT") {
            _t = 1;
            index = 0;
          }
          const spNext = this.idMatrix[_t][index + add];
          if (spNext) {
            this.focusId = spNext;
          }
        }
      }

      this.handleScroll();
    },
    handleEnter() {
      if (this.focusId) {
        // spending | income
        this.handleClickType(this.focusId);
      }
    },
    handleScroll() {
      console.log("[handleScroll()]");
      if (this.focusId) {
        const ele = document.getElementById(this.focusId);
        ele.scrollIntoView({
          behavior: "smooth",
        });
        // TODO
      } else {
        console.log("scroll but no focus id");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bill-type-selector {
  border: 1px solid rgb(220, 220, 220);
  width: 550px;
  // 20px是抽屉标题是上边距，25px是抽屉标题的文字高度，32px是抽屉标题的下边距，60px是抽屉搜索组件的总高度，20px是收支类型列表下面想留个高
  height: calc(100vh - 20px - 25px - 32px - 60px - 20px);

  user-select: none;

  display: flex;
  .bill-type-selector-side {
    height: 100%;
    width: 50%;
    .selector-title {
      height: 45px;
      width: 100%;
      padding-left: 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgb(220, 220, 220);
    }
    .selector-items-container {
      width: 100%;
      height: calc(100% - 45px);
      display: flex;
      justify-content: center;
      align-items: center;
      .selector-items {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border: 1px solid rgb(230, 230, 230);
        border-radius: 6px;
        overflow-y: auto;
        .selector-item {
          height: 44px;
          display: flex;
          border-bottom: 1px solid rgb(235, 235, 235);
          cursor: pointer;
          transition: all 0.3s;
          .selector-item-icon-container {
            width: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            .selector-item-icon {
              font-size: 20px;
            }
          }
          .selector-item-name {
            width: calc(100% - 44px - 78px);
            border-left: 1px solid rgb(205, 205, 205);
            border-right: 1px solid rgb(208, 208, 208);
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 18px;
            padding-left: 10px;
          }
          .selector-item-tag-container {
            width: 78px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .selector-item:hover,
        .selector-item-select {
          background-color: #188cff;
          .selector-item-icon-container {
            .selector-item-icon {
              color: white !important;
            }
          }
          .selector-item-name {
            color: white;
          }
        }
      }
    }
  }
  .bill-type-selector-left {
  }
  .bill-type-selector-right {
    border-left: 1px solid rgb(220, 220, 220);
  }
}
</style>
