<template>
  <el-dialog
    :visible.sync="sortVisible"
    width="450px"
    :before-close="handleBeforeCloseSort"
    class="sort-dialog"
    title="排序"
    :close-on-click-modal="false"
  >
    <div class="sort-dialog-body" v-loading="listLoading">
      <div class="date-selector">
        <el-button size="small" @click="handleDayJump(-1)">上一日</el-button>
        <el-date-picker
          v-model="sortFilterDate"
          align="left"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          size="small"
          clearable
          class="filter-element"
          style="width: 140px"
          @change="handleGetBillListSort"
        />
        <el-button size="small" @click="handleDayJump(1)">下一日</el-button>
      </div>
      <div class="no-data" v-if="!sortList.length">（无数据）</div>
      <Draggable
        class="list-group"
        tag="ul"
        v-model="sortList"
        v-bind="{
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost',
        }"
        @start="
          drag = true;
          isTimeGenerated = false;
        "
        @end="drag = false"
        :style="sortList.length ? 'border: 1px solid rgb(219, 219, 219)' : ''"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div
            class="list-group-item"
            v-for="element in sortList"
            :key="element._id"
          >
            <div class="sort-item-time">{{ element.time }}</div>
            <div class="sort-item-description">{{ element.description }}</div>
            <div class="sort-item-amount">
              <span v-if="element.method === 'spending'" style="color: green">
                - {{ getDisplayAmount(element.amount) }}
              </span>
              <span v-if="element.method === 'income'" style="color: red">
                + {{ getDisplayAmount(element.amount) }}
              </span>
            </div>
          </div>
        </transition-group>
      </Draggable>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCloseSort">取 消</el-button>
      <el-button @click="handleReverse">倒转</el-button>
      <el-button @click="handleSortTime">生成时间</el-button>
      <el-button
        type="primary"
        @click="handleSort"
        :disabled="!isTimeGenerated"
        :loading="saveLoading"
      >
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import { getDisplayAmount } from "@/utils/display.js";
import draggable from "vuedraggable";
export default {
  name: "BillSortDialog",
  components: {
    Draggable: draggable,
  },
  props: {
    sortVisible: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      listLoading: false,
      saveLoading: false,
      sortFilterDate: "",
      sortList: [],
      drag: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      // 是否已经生成时间了
      isTimeGenerated: false,
    };
  },
  methods: {
    getDisplayAmount,
    // 排序相关方法
    /**
     * 关闭排序对话框的回调函数
     */
    handleBeforeCloseSort() {
      this.handleCloseSort();
    },
    /**
     * 关闭排序对话框
     */
    handleCloseSort() {
      ({ sortList: this.sortList, sortFilterDate: this.sortFilterDate } =
        this.$options.data());

      this.$emit("update:sortVisible", false);
    },
    handleReverse() {
      this.sortList = this.sortList.reverse();
    },
    handleSortTime() {
      let h = 1;
      let m = 1;
      for (let i = this.sortList.length - 1; i >= 0; i--) {
        const fullH = h < 10 ? "0" + h : h;
        const fullM = m < 10 ? "0" + m : m;
        const time = fullH + ":" + fullM + ":00";
        this.sortList[i].time = time;

        m += 1;
        if (m === 60) {
          m = 0;
          h += 1;
        }
      }

      this.isTimeGenerated = true;
    },
    async handleSort() {
      this.saveLoading = true;
      const res = await Api.BillApi.batchEdit(this.sortList).finally(() => {
        this.saveLoading = false;
      });
      if (res && res.code === 0) {
        this.$message.success("重排成功");
      } else {
        this.$message.error("重排失败");
      }
    },
    async handleGetBillListSort() {
      this.isTimeGenerated = false;
      if (this.sortFilterDate) {
        this.sortList = [];
        this.listLoading = true;
        const res = await Api.BillApi.queryBillByTimeFilter({
          date: this.sortFilterDate,
        }).finally(() => {
          this.listLoading = false;
        });
        if (res && res.code === 0) {
          const { data } = res;
          this.sortList = data.records || [];
          this.$message.success("获取当日账单成功");
        } else {
          this.$message.error("按照输入的时间来获取账单失败");
        }
      } else {
        this.sortList = [];
      }
    },
    handleDayJump(type) {
      if (this.sortFilterDate) {
        const dateArr = this.sortFilterDate
          .split("-")
          .map((item) => Number(item));
        let year = dateArr[0];
        let month = dateArr[1];
        let day = dateArr[2];
        if (type === 1) {
          day += 1;
          if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
            // 大
            if (day > 31) {
              month += 1;
              day = 1;
            }
          } else if ([4, 6, 9, 11].includes(month)) {
            // 小
            if (day > 30) {
              month += 1;
              day = 1;
            }
          } else {
            if (year % 4 === 0) {
              //  润2
              if (day > 29) {
                month += 1;
                day = 1;
              }
            } else {
              //  平2
              if (day > 28) {
                month += 1;
                day = 1;
              }
            }
          }

          if (month === 13) {
            year += 1;
            month = 1;
          }
        } else if (type === -1) {
          day -= 1;
          if (day === 0) {
            month -= 1;
            if (month === 0) {
              year -= 1;
              month = 12;
            }

            if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
              // 大
              day = 31;
            } else if ([4, 6, 9, 11].includes(month)) {
              // 小
              day = 30;
            } else {
              if (year % 4 === 0) {
                //  润2
                day = 29;
              } else {
                //  平2
                day = 28;
              }
            }
          }
        }
        let d = "";

        const monthStr = month < 10 ? "0" + month : "" + month;
        const dayStr = day < 10 ? "0" + day : "" + day;
        d = year + "-" + monthStr + "-" + dayStr;
        this.sortFilterDate = d;
        this.handleGetBillListSort();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.sort-dialog {
  .sort-dialog-body {
    .date-selector {
      .filter-element {
        margin: 0px 10px;
      }
    }

    .flip-list-move {
      transition: transform 0.5s;
    }
    .no-move {
      transition: transform 0s;
    }
    .ghost {
      opacity: 0.5;
      background: #c8ebfb;
    }
    .list-group {
      padding-left: 0px;
      border-radius: 6px;
      overflow: hidden;
    }
    .list-group-item {
      border: 1px solid rgb(238, 238, 238);
      cursor: move;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      .sort-item-time {
        width: 90px;
      }
      .sort-item-description {
        width: calc(100% - 90px - 95px);
      }
      .sort-item-amount {
        width: 95px;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
      }
    }

    .no-data {
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      color: #444;
    }
  }
}
</style>
